import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'

import ChartComponent from '../../components/graph'
import { Spinner } from '../../components/graph/Spinner'
import About from '../../components/Portfolio/About'
import Seo from '../../components/Seo'
import Spacer from '../../components/Spacer'
import { aboutDataCoins } from '../../constants/portfolio-constants'
import { getCoinColor } from '../../utils/getcoincolor'
import { numberFormatter, processChartData } from '../../utils/portfolioutils'
import { getDataFromAPI } from '../../utils/serviceUtils'

const handleFetchErrors = response => {
  if (!response.ok) throw Error(response.statusText)
  return response
}

const Coin = ({ params, location }) => {
  const [data, setData] = useState([])
  const [range, setRange] = useState('All')
  const [isLoading, setIsLoading] = useState(false)
  const [aboutDescription, setAboutDescription] = useState('')
  const [coinData, setCoinData] = useState([])

  const handleRangeChange = rangeValue => {
    setRange(rangeValue)
  }

  const fetchData = useCallback(async () => {
    if (!params.id) {
      return
    }
    const coinInfo = location?.state?.data

    if (coinInfo) {
      setAboutDescription(coinInfo.description)
      const computedData = aboutDataCoins
        .map(item => ({
          ...item,
          content: coinInfo?.coinMarketData[item.key]
            ? `${item.prefix || ''} ${numberFormatter(
                coinInfo?.coinMarketData[item.key]
              )}`
            : '',
        }))
        .filter(compItem => !!compItem.content)
      setCoinData(computedData)
    }
    try {
      setIsLoading(true)
      const chartData = await getDataFromAPI(
        `https://api.emberfund.io/charts/v4/${params.id}/`
      )
      handleFetchErrors(chartData)
      const parsedChartData = await chartData.json()
      setData(parsedChartData.dataPoints)
      setIsLoading(false)
    } catch (error) {
      setData([])
      setIsLoading(false)
    }
  }, [location?.state?.data, params])

  useEffect(() => {
    if (!location?.state?.data) {
      navigate('/portfolio')
    }
  }, [location?.state?.data])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchData()
  }, [fetchData])

  if (isLoading) {
    return (
      <>
        <Spacer size={[200, 250]} />
        <Spinner />
      </>
    )
  }

  return (
    <>
      <Seo title={`Details - ${location?.state?.data?.coinNiceName}`} />

      <Spacer size={[100, 150]} />

      {!!data && Array.isArray(data[range]) && (
        <ChartComponent
          isCoinPage
          lineColor={getCoinColor(location.state?.data?.coinName || '')}
          name={location?.state?.data?.coinNiceName}
          shortName={location.state?.data?.coinName}
          data={processChartData(data[range])}
          onRangeChange={handleRangeChange}
          range={range}
        />
      )}

      <Spacer size={[50, 50]} />

      {!!aboutDescription && (
        <About title="About" text={aboutDescription} items={coinData} />
      )}

      <Spacer size={[50, 50]} />
    </>
  )
}

export default Coin
